import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styles from "../../scss/modules/service_link.module.scss"

export default (service) => {

  return (

    <div className={styles.service_link}>
      <div className={styles.service_link__image}>
      <Link to={service.url} alt={"View '"+service.name}>
        <Img fluid={ service.image.childImageSharp.fluid }
          alt={"View "+service.title}
        />
      </Link>
      </div>
      <div className={styles.service_link__content}>
        <h2><a href={service.url}>{service.title}</a></h2>
      </div>
    </div>
  )
}
