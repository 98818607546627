import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout";
import Service from "../components/services/service-link"
import styles from "../scss/layouts/services_index.module.scss"
import headerStyles from "../scss/modules/text_header.module.scss"
import ServicesCta from "../components/cta/cta_services";
import ClientLogos from "../components/client-logos";


export default ({ data }) => {
  const pageData = data.markdownRemark;
  const services = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />
      
        <div className={headerStyles.text_header__header_green}>
          <div className={headerStyles.text_header__header_inner}>
            <h1>{ pageData.frontmatter.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
          </div>
        </div>

      <div className={styles.service_index}>
        <div className={styles.service_index__inner}>
          { services.nodes.map(function(service, index) {
            return <Service key={ index }
                          title={ service.frontmatter.title }
                          excerpt={ service.frontmatter.excerpt }
                          image={ service.frontmatter.featuredImage }
                          url={ service.fields.slug}/>;
          }) }
        </div>
      </div>
      <ClientLogos />
      <ServicesCta />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
      }
      html
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/services\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: ASC
      })
       {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          rank
          excerpt
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 5000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`