import React from "react";
import styles from "../../scss/modules/cta_block.module.scss";
import cta from "../../scss/elements/buttons.module.scss";
import image from "../../images/cta_projects@2x.jpg";
import { Link } from "gatsby"

export default () => (
    <div className={[styles.cta, styles.cta__pink_bg].join(' ')}>
        <div className={styles.cta__inner}>
            <div className={styles.cta__content}>
                <h2>View our latest projects</h2>
                <p>Take a look at the range of planning and environmental projects we've worked on.</p>
                <Link className={cta.arrow_button} to="/projects">View all projects</Link>
            </div>
            <div className={styles.cta__image}>
                <img src={image} alt={"View all projects"}></img>
            </div>
        </div>
    </div>
  )
  